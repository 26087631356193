<template>
    <div>
        <!-- 认证本人手机 -->
        <div v-show="isphone">
            <div class="dialog-text">
                <div class="dialog-text-left">
                    账号ID
                </div>
                <div class="dialog-text-right">
                    {{sonloginData.uid}}
                </div>
            </div>
            <div class="dialog-text">
                <div class="dialog-text-left">
                    手机号
                </div>
                <div class="dialog-text-right">
                    {{phone}}
                </div>
            </div>
            <!-- 认证本人手机form表单 -->
            <el-form :model="attestationForm" :rules="attestationRules" ref="attestationRef" label-width="90px" label-position="left">
                <el-form-item label="验证码" prop="authcode">
                    <el-input v-model="attestationForm.authcode" style="margin-right:15px;"></el-input>
                    <el-button plain round size="small" v-show="show" @click="getCode">发送验证码</el-button>
                    <el-button plain round size="small" v-show="!show" disabled>重新发送 {{count}} s</el-button>
                </el-form-item>
                <p>*如果因手机号丢失，无法进行验证，请联系客服</p>
                <div class="phoneDialog">
                    <el-button @click="cancelChange">取 消</el-button>
                    <el-button @click="attestationPhone" type="primary">确 定</el-button>
                </div>
            </el-form>
        </div>
        <!-- 更改手机号码 -->
        <div v-show="!isphone">
            <div class="dialog-text">
                <div class="dialog-text-left" style="width:110px;">
                    账号ID
                </div>
                <div class="dialog-text-right">
                    {{sonloginData.uid}}
                </div>
            </div>
            <div class="dialog-text">
                <div class="dialog-text-left" style="width:110px;">
                    原手机号码
                </div>
                <div class="dialog-text-right">
                    {{phone}}
                </div>
            </div>
            <!-- 更改手机号码form表单 -->
            <el-form :model="changeForm" :rules="changeRules" ref="changeRef" label-width="110px" class="demo-ruleForm" label-position="left">
                <el-form-item label="新手机号码" prop="phone">
                    <el-input v-model="changeForm.phone" style="margin-right:15px;"></el-input>
                </el-form-item>
                <el-form-item label="验证码" prop="authcode">
                    <el-input v-model="changeForm.authcode" style="margin-right:15px;"></el-input>
                    <el-button plain round size="small" v-show="show" @click="getCode">发送验证码</el-button>
                    <el-button plain round size="small" v-show="!show" disabled>重新发送 {{count}} s</el-button>
                </el-form-item>
                <div class="phoneDialog">
                    <el-button @click="cancelChange">取 消</el-button>
                    <el-button type="primary" @click="changePhone">确 定</el-button>
                </div>
            </el-form>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        sonloginData:Object,
        phone:String
    },
    data(){
        return{
            // 验证码显示状态
            show: true,
            // 倒计时秒数
            count: '',
            // 定时器
            timer: null,
            // 更换手机号码的切换
            isphone:true,
            // 认证本人手机
            attestationForm:{
                authcode:''
            },
            // 认证本人手机号码校验规则
            attestationRules:{
                authcode: [ 
                    { required: true, message: '请输入验证码', trigger: 'blur' },
                    { min: 6, max: 6, message: '长度是6个数字哦', trigger: 'blur' }
                ],
            },
            // 修改手机号码
            changeForm:{
                phone:'',
                project_name:'',
                authcode:''
            },
            // 修改手机号码校验规则
            changeRules:{
                authcode: [
                    { required: true, message: '请输入验证码', trigger: 'blur' },
                    { min: 6, max: 6, message: '长度是6个数字哦', trigger: 'blur' }
                ],
                phone: [
                    { required: true, message: '请输入新手机号码', trigger: 'blur' },
                    { min: 11, max: 11, message: '请输入正确的手机号码', trigger: 'blur' },
                ],
            },
            // 更换手机号码voucher
            voucher:''
        }
    },
    // created(){
    //     console.log(this.sonloginData)
    // },
    methods:{
        // 认证手机
        attestationPhone(){
            this.$refs.attestationRef.validate(async(valid)=>{
                if(!valid) return
                const {data:res} = await this.$http.post('/c-admin/check_verification_code/',{
                    phone:this.sonloginData.phone,
                    code:this.attestationForm.authcode
                })
                if(res.status!==200)return this.$message({showClose:true,message:res.message,type:'error'});
                this.show = true;
                clearInterval(this.timer);
                this.timer = null;
                this.isphone=false
                this.voucher=res.data
            })
        },
        // 更换手机号码
        changePhone(){
            this.$refs.changeRef.validate(async(valid)=>{
                if(!valid) return
                const {data:res} = await this.$http.post('c-admin/update_phone/',{
                    phone:this.changeForm.phone,
                    voucher:this.voucher,
                    code:this.changeForm.authcode
                })
                if(res.status!==200)return this.$message({showClose:true,message:res.message,type:'error'});
                this.$message({showClose:true,message:'更换手机号码成功!',type:'success'});
                this.$emit('sonPhoneCancel')
            })
        },
        // 验证码倒计时
        async getCode(){
            if(this.isphone){
                const {data:res}=await this.$http.get('/c-admin/verification_code/',{
                    params:{
                        phone:this.sonloginData.phone,
                        sing_code:'WINDATA'
                    }
                })
                if(res.status!==200) return this.$message({showClose:true,message:'获取验证码失败',type:'error'});
                this.$message({showClose:true,message:'验证码已发送，请注意查收！',type:'success' });
            }else{
                if(this.changeForm.phone.length!==11) return this.$message({showClose:true,message:'请输入正确的手机号码再获取验证码',type:'error'});
                const {data:res}=await this.$http.get('/c-admin/verification_code/',{
                    params:{
                        phone:this.changeForm.phone,
                        sing_code:'WINDATA'
                    }
                })
                if(res.status!==200) return this.$message({showClose:true,message:'获取验证码失败',type:'error'});
                this.$message({showClose:true,message:'验证码已发送，请注意查收！',type:'success' });
            }
            const TIME_COUNT = 60;
            if (!this.timer) {
                this.count = TIME_COUNT;
                this.show = false;
                this.timer = setInterval(() => {
                    if (this.count > 0 && this.count <= TIME_COUNT) {
                        this.count--;
                    } else {
                        this.show = true;
                        clearInterval(this.timer);
                        this.timer = null;
                    }
                }, 1000)
            }
        },
        // 修改手机的表单清空
        PhoneResetFields(){
            this.$refs.attestationRef.resetFields()
            this.$refs.changeRef.resetFields()
            this.isphone=true
        },
        // 点击取消修改
        cancelChange(){
            this.$emit('sonPhoneCancel')
        },
    }
}
</script>

<style lang="less" scoped>
.el-input{
    width: 234px;
}
.dialog-text{
    height: 40px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .dialog-text-left{
        width: 90px;
        padding-left: 10px;
        font-size: 16px;
        color: #252530
    }
    .dialog-text-right{
        font-size: 16px;
        color: #252530;
        font-weight: 400;
    }
}
.phoneDialog{
    display: flex;
    justify-content: center;
    margin-top: 42px;
    .el-button{
        width: 100px;
        height: 44px;
    }
}
p{
    color: #828494;
}
/deep/.el-form-item__label{
    color: #252530;
    font-size: 16px;
}
</style>