<template>
    <!-- 账号设置 -->
    <div>
        <div class="title">
            <h1>账号信息</h1>
        </div>
        <div class="text">
            <div class="text-left">账号名称</div>
            <div class="text-right-b">{{loginData.nick}}</div>
        </div>
        <div class="text">
            <div class="text-left">账号ID</div>
            <div class="text-right-b">{{loginData.uid}}</div>
        </div>
        <div class="text">
            <div class="text-left">绑定手机号</div>
            <div class="text-right">{{phone}}</div>
            <div style="height:60px;display:flex;align-items: center;">
                <el-button @click="phoneVisible=true">更换手机号</el-button>
            </div>
        </div>
        <div class="text">
            <div class="text-left">密码</div>
            <div class="text-right">*************</div>
            <div style="height:60px;display:flex;align-items: center;">
                <el-button @click="PasswordVisible=true">修改密码</el-button>
            </div>
        </div>
        <!-- <div class="text">
            <div class="text-left">关联渠道</div>
            <div class="text-right-b">{{loginData.channel_name}}</div>
        </div> -->
        <!-- <div>
            <a :href="'https://mp.weixin.qq.com/cgi-bin/componentloginpage?component_appid=wxe71d642d7dad2367&pre_auth_code='+preauthcode+'&redirect_uri=https://c.vnla.cn/&auth_type=3'">微信授权</a>
        </div> -->
        <!-- https://mp.weixin.qq.com/cgi-bin/componentloginpage?component_appid=wxe71d642d7dad2367&pre_auth_code=${}&redirect_uri=https://c.vnla.cn/login&auth_type=3 -->
        <!-- <div class="text">
            <div class="text-left">最近登录</div>
            <div class="text-right-b">WDDD<br/>2021.02.11 15:00 广东广州</div>
        </div> -->
        <!-- 更换手机dialog -->
        <el-dialog title="更换手机号码" :visible.sync="phoneVisible" center @close="phoneClose">
            <changePhone :sonloginData="loginData" ref="changePhoneRef" :phone="phone" @sonPhoneCancel="phoneCancel"/>
        </el-dialog>
        <!-- 修改密码dialog -->
        <el-dialog title="修改密码" :visible.sync="PasswordVisible" center @close="passwordClose">
            <changePassword :sonloginData="loginData"  ref="changePasswordRef" @sonPasswordCancel="passwordCancel" />
        </el-dialog>
    </div>
</template>
<script>
import global from '../../assets/js/global'
import axios from 'axios'// 引用axios
import changePhone from '../../components/AccountSettings/changePhone.vue'
import changePassword from '../../components/AccountSettings/changePassword.vue'
export default {
    components:{
        changePhone,
        changePassword
    },
    data(){
        return{
            // 账号信息
            loginData:{},
            // 隐藏手机号码
            phone:'',
            // 更换手机的dialog显示状态
            phoneVisible:false,
            // 修改密码的dialog显示状态
            PasswordVisible:false,
            preauthcode:''
        }
    },
    created(){
        // axios.get("http://192.168.1.202:8004/third/get_pre_auth_code?appid=wxe71d642d7dad2367")
        // axios.get(global.wechatURL+"third/get_pre_auth_code?appid=wxe71d642d7dad2367")
        // .then(res => {
        //     this.preauthcode=res.data.data
        //     console.log(this.preauthcode)
        // });
        this.loginData = JSON.parse(this.$global.getCookie("loginData"))
        this.phone = this.loginData.phone.substr(0,3) + "****" + this.loginData.phone.substr(7)
        console.log(this.phone)
    },
    methods:{
        // 关闭更改手机Dialog回调
        phoneClose(){
            this.$refs.changePhoneRef.PhoneResetFields();
        },
        // 点击取消关闭修改手机的dialog
        phoneCancel(){
            this.phoneVisible=false
        },
        // 关闭修改密码dialog回调
        passwordClose(){
            this.$refs.changePasswordRef.PassworResetFields();
        },
        // 点击取消关闭修改密码的dialog
        passwordCancel(){
            this.PasswordVisible=false
        },
    }
}
</script>
<style lang="less" scoped>
    .title{
        position: relative;
        width: 162px;
        display: flex;
        margin-left: 40px;
        // margin-top: 20px;
        justify-content: center;
        h1{
            font-size: 14px;
        }
    }
    .title::after{
        content: '';
        position: absolute;
        bottom: 0;
        width: 162px;
        height: 2px;
        background-image: linear-gradient(to right, #FF6056 , #ffffff);
    }
    .text{
        display: flex;
        .el-button{
            width: 100px;
        }
    }
    .text-left{
        width: 130px;
        padding: 20px 0;
        display: flex;
        justify-content: flex-end;
        color: #828494;
    }
    .text-right{
        margin-left: 30px;
        width: 180px;
        padding: 20px 0;
    }
    .text-right-b{
        padding:20px 0 0 30px;
    }
    /deep/.el-dialog__title{
        font-size: 18px;
        font-weight: 700;
    }
    // /deep/.el-dialog{
    //     min-width: 748px!important;
    // }
    .dialog{
        display: flex;
        font-style: 16px;
    }
    .dialog-left{
        width: 100px;
        height: 40px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-right: 40px;
    }
    .dialog-right{
        height: 40px;
        display: flex;
        align-items: center;
    }
    p{
        margin-left: 60px;
        color: #828494;
        font-size: 14px;
    }
    /deep/.el-dialog--center .el-dialog__body,.el-dialog__body{
        padding:0 25px 25px 50px;
    }
    .el-form-item {
        margin-bottom: 0px;
    }
    /deep/.el-dialog{
        width: 748px;
    }
</style>