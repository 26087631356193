<template>
    <div>
        <div class="dialog-text">
            <div class="dialog-text-left">账号ID</div>
            <div class="dialog-text-right">{{sonloginData.uid}}</div>
        </div>
        <el-form :model="changePasswordBody" :rules="passwordRules" ref="PasswordRef" label-width="110px" class="demo-ruleForm" label-position="left">
            <el-form-item label="原密码" prop="password">
                <el-input 
                    v-model="changePasswordBody.password" 
                    placeholder="请输入原密码" 
                    type="password">
                </el-input>
            </el-form-item>
            <el-form-item label="新密码" prop="new_password">
                <el-input 
                    v-model="changePasswordBody.new_password" 
                    placeholder="6-16位，必须包含数字和字母" 
                    type="password">
                </el-input>
            </el-form-item>
            <el-form-item label="确认密码" prop="new_password1">
                <el-input 
                v-model="changePasswordBody.new_password1" 
                placeholder="再次输入新密码" type="password"></el-input>
            </el-form-item>
            <div class="dialog-text">
            <div class="dialog-text-left">绑定手机号</div>
                <div class="dialog-text-right">{{md5_phone}}</div>
            </div>
            <el-form-item label="验证码" prop="code">
                <el-input v-model="changePasswordBody.code" placeholder="请输入验证码" style="margin-right:15px;"></el-input>
                <el-button plain round size="small" v-show="show" @click="getCode">发送验证码</el-button>
                <el-button plain round size="small" v-show="!show" disabled>重新发送 {{count}} s</el-button>
            </el-form-item>
        </el-form>
        <div class="dialog-footer">
            <span slot="footer" >
                <el-button @click="cancelChange">取 消</el-button>
                <el-button @click="changePassword('PasswordRef')" type="primary">确 定</el-button>
            </span>
        </div>
    </div>
</template>

<script>
import md5 from 'js-md5';
export default {
    props:{
        sonloginData:Object,
    },
    data(){
        // 判断密码是否一致
        var validatePass2 = (rule, value, callback) => {
            if (value === '') {
            callback(new Error('请再次输入密码'));
            } else if (value !== this.changePasswordBody.new_password) {
            callback(new Error('两次输入密码不一致!'));
            } else {
            callback();
            }
        };
        return{
            phone:'13046223283',
            code:'',
            // 验证码显示状态
            show: true,
            // 倒计时秒数
            count: '',
            // 定时器
            timer: null,
            // 修改密码的body
            changePasswordBody:{
                password:'',
                new_password:'',
                new_password1:'',
                code:''
            },
            // 输入密码的校验正则
            passwordRules:{
                password:[
                    { required: true, message: '请输入原密码', trigger: 'blur' },
                ],
                new_password:[
                    { required: true, message: '请输入新密码', trigger: 'blur' },
                    // { pattern: /^(?=.*\d)(?=.*[a-zA-Z]).{6,16}$/, message: '请输入6-16位，必须包含数字和字母', trigger: 'blur' },
                ],
                new_password1:[
                    { required: true, message: '请再次输入新密码', trigger: 'blur' },
                    { required: true, trigger: "blur", validator: validatePass2 }
                ],
                code:[
                    { required: true, message: '请输入验证码', trigger: 'blur' },
                    { min: 6, max: 6, message: '长度在 6个字符', trigger: 'blur' }
                ]

            },
        }
    },
    created(){
        this.phone=JSON.parse(this.$global.getCookie('loginData')).phone
        this.md5_phone = this.phone.substr(0,3) + "****" + this.phone.substr(7)
    },
    methods:{
        // 验证码倒计时
        async getCode(){
            const {data:res}=await this.$http.get('/c-admin/verification_code/',{
                params:{
                    phone:this.phone,
                    sing_code:'WINDATA'
                }
            })
            if(res.status!==200) return this.$message({showClose:true,message:'获取验证码失败',type:'error'});
            this.$message({showClose:true,message:'验证码已发送，请注意查收！',type:'success' });
            const TIME_COUNT = 60;
            if (!this.timer) {
                this.count = TIME_COUNT;
                this.show = false;
                this.timer = setInterval(() => {
                    if (this.count > 0 && this.count <= TIME_COUNT) {
                        this.count--;
                    } else {
                        this.show = true;
                        clearInterval(this.timer);
                        this.timer = null;
                    }
                }, 1000)
            }
        },
        // 确认修改密码
        changePassword(PasswordRef){
            this.$refs[PasswordRef].validate( async (valid)=>{
                if(!valid) return;
                const {data:res} = await this.$http.put('c-admin/update_password/',{
                    old_password:md5(this.changePasswordBody.password),
                    new_password:md5(this.changePasswordBody.new_password),
                    code:this.changePasswordBody.code
                })
                console.log(res)
                if(res.status!==200) return this.$message({ showClose: true, message: res.message, type: 'error'})
                this.$message({ showClose: true, message: '修改密码成功', type: 'success'})
                this.$emit('sonPasswordCancel')
            })
        },
        // 修改密码的表单清空
        PassworResetFields(){
            this.$refs.PasswordRef.resetFields()
        },
        // 点击取消修改
        cancelChange(){
            this.$emit('sonPasswordCancel')
        },
    }
}
</script>

<style lang="less" scoped>
.el-input{
    width: 234px;
}
.dialog-text{
    height: 40px;
    display: flex;
    align-items: center;
    margin-bottom: 22px;
    .dialog-text-left{
        width: 100px;
        padding-left: 10px;
        font-size: 16px;
        color: #252530;
    }
    .dialog-text-right{
        font-size: 16px;
        color: #252530;
        font-weight: 400;
    }
}
.dialog-footer{
    display: flex;
    justify-content: center;
    margin-top: 42px;
    .el-button{
        width: 100px;
        height: 44px;
    }
}
/deep/.el-form-item__label{
    color: #252530;
}
</style>